import { toBase64 } from '../encode.utils';
import { visitorInstance } from '../visitor-providers.utils';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerLoginSuccess {
  document: string;
  priceList: string;
  docType: string;
  externalDistributorName: string;
}

export const triggerLoginSuccessEvent = ({
  document,
  priceList,
  externalDistributorName,
  docType,
}: ITriggerLoginSuccess) => {
  const data = {
    event: 'virtualEventA2',
    tipoevento: 'login exitoso',
    userID: toBase64(document),
    giro: priceList,
    tipousuario: externalDistributorName,
    tipodocumento: docType,
  };
  pushObjectDataLayer(data, triggerLoginSuccessEvent.name);
};

interface ITtriggerInitializeUser {
  documentNumber: string;
  priceList: string;
  externalDistributorName: string;
  documentType: string;
  department: string;
  district: string;
}

export const triggerInitializeUserEvent = async (customer: ITtriggerInitializeUser) => {
  const userId = toBase64(customer.documentNumber);
  const { id } = await visitorInstance.getInformation();

  const data = {
    event: 'login_Status',
    loginStatus: 'Sesión iniciada',
    userID: userId,
    tipoevento: 'login exitoso',
    user_id: userId,
    giro: customer.priceList,
    tipousuario: customer.externalDistributorName,
    tipodocumento: customer.documentType,
    departamento: customer.department,
    distrito: customer.district,
    Fingerprint: id,
  };
  pushObjectDataLayer(data, triggerInitializeUserEvent.name);
};
