/* eslint-disable no-console */
import type { Agent } from '@fingerprintjs/fingerprintjs';
import { IVisitorInformation, IVisitorProvider } from 'core/model/interfaces/visitor.interface';

export class FingerprintProvider implements IVisitorProvider {
  private fingerprintAgent: Promise<Agent> | null = null;

  constructor() {
    this.install();
  }

  async install() {
    const FingerprintJSModule = await import('@fingerprintjs/fingerprintjs');
    this.fingerprintAgent = FingerprintJSModule.load();
  }

  async getInformation(): Promise<IVisitorInformation> {
    if (!this.fingerprintAgent) {
      console.warn('FingerprintJS is not initialized');
      return { id: null };
    }
    const agent = await this.fingerprintAgent;
    const { visitorId } = await agent.get();

    return { id: visitorId };
  }
}
